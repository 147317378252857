import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => (
  <Layout>
    <SEO title="Privacy" />
    <div className="privacy-policy privacy-wrapper">
      <h1 className="h1">Privacy Policy</h1>
      <p className="p">
        This privacy policy (“Policy”) describes how Upright Labs, Inc and its
        related companies (“Company”) collect, use and share personal
        information of consumer users of this website, www.uprightlabs.com (the
        “Site”), Upright Local (the “App”), and Upright Consulting (the
        “Services”). This Policy also applies to any of our other websites that
        post this Policy. This Policy does not apply to websites that post
        different statements.
      </p>

      <h4 className="h4">
        <strong>What We Collect</strong>
      </h4>
      <p className="p">We get information about you in a range of ways.</p>
      <p className="p">
        <strong>Information You Give Us.</strong> We collect your‎ name, email
        address, username, password as well as other information you directly
        give us on our Site. If you choose to link a third party account with
        our Site, we store encrypted access to that account. We will also
        collect information from any third party accounts you choose to link to
        our Site, to the extent necessary to fulfill your data integration,
        collection, and reporting requests.
      </p>
      <p className="p">
        <strong>Information We Get From Others.</strong> We may get information
        about you from other sources. We may add this to information we get from
        this Site.
      </p>
      <p className="p">
        <strong>Information Automatically Collected.</strong> We automatically
        log information about you and your computer. For example, when visiting
        our Site, we log your computer operating system type, browser type,
        browser language, the website you visited before browsing to our Site,
        pages you viewed, how long you spent on a page, access times and
        information about your use of and actions on our Site. We may employ
        usage tracking, including cookies, FullStory, and Google Analytics to
        improve our Site.
      </p>
      <p className="p">
        <strong>Cookies.</strong> We may log information using "cookies."
        Cookies are small data files stored on your hard drive by a website. We
        may use both session Cookies (which expire once you close your web
        browser) and persistent Cookies (which stay on your computer until you
        delete them) to provide you with a more personal and interactive
        experience on our Site. This type of information is collected to improve
        the Site and tailor the experience with us to meet your special
        interests and needs.
      </p>
      <h4 className="h4">
        <strong>USE OF PERSONAL INFORMATION</strong>
      </h4>
      <p className="p">We use your personal information as follows:</p>
      <ul>
        <li>
          We use your personal information to operate, maintain, and improve our
          sites, products, and services.
        </li>
        <li>
          We use your personal information to provide customers with the
          information they need to use our Site, including confirmations,
          invoices, technical notices, updates, security alerts, and other
          customer relations messages.
        </li>
        <li>We may also use your data for analytics purposes</li>
        <li>
          We use your personal information to provide and deliver products,
          services, and assistance requested by customers.
        </li>
      </ul>
      <h4 className="h4">
        <strong>SHARING OF PERSONAL INFORMATION</strong>
      </h4>
      <p className="p">We may share personal information as follows:</p>
      <ul>
        <li>
          We may share personal information with your consent. For example, you
          may let us share personal information with others for their own
          marketing uses. Those uses will be subject to their privacy policies.
        </li>
        <li>
          We may share personal information when we negotiate and/or finalize a
          business agreement involving the sale or transfer of all or a part of
          our business or assets. These deals can include any merger, financing,
          acquisition, or bankruptcy transaction or proceeding.
        </li>
        <li>
          We may share personal information for legal, protection, and safety
          purposes.
        </li>
        <li>
          We may share information to comply with relevant laws and regulations.
        </li>
        <li>
          We may share information to respond to lawful requests and legal
          processes.
        </li>
        <li>
          We may share information in an emergency. This includes protecting the
          safety of our employees and agents, our customers, or any person.
        </li>
        <li>
          We may share information with those who need it to do work for us,
          including but not limited to partners and vendors, in order to fulfill
          our obligations to our customers.
        </li>
      </ul>
      <p className="p">
        We may also share aggregated and/or anonymized data with others for
        their own uses.
      </p>
      <h4 className="h4">
        <strong>INFORMATION CHOICES AND CHANGES</strong>
      </h4>
      <p className="p">
        Our marketing emails tell you how to “opt-out.” If you opt out, we may
        still send you non-marketing emails. Non-marketing emails include emails
        about your accounts and our business dealings with you.
      </p>
      <p className="p">
        You may send requests about your personal information to our Contact
        Information below. You can request to change contact choices, opt-out of
        our sharing with others, and update your personal information.
      </p>
      <p className="p">
        You can choose to remove and reject cookies from our Site with your
        browser settings. Many browsers are set to accept cookies until you
        change your settings. If you remove or reject our cookies, it could
        affect how our Site works for you.{" "}
      </p>
      <h4 className="h4">
        <strong>CONTACT INFORMATION</strong>
      </h4>
      <p className="p">
        We welcome your comments or questions about this privacy policy. You may
        email us at{" "}
        <a href="mailto:team@uprightlabs.com">team@uprightlabs.com</a>.
      </p>
    </div>
  </Layout>
)

export default Privacy
